// export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";

// const fetchProductsSuccess = products => ({
//   type: FETCH_PRODUCTS_SUCCESS,
//   payload: products
// });

// // fetch products
// export const fetchProducts = products => {
//   return dispatch => {
//     dispatch(fetchProductsSuccess(products));
//   };
// };

import axios from "axios";

export const FETCH_PRODUCTS_REQUEST = "FETCH_PRODUCTS_REQUEST";
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const FETCH_PRODUCTS_FAILURE = "FETCH_PRODUCTS_FAILURE";

export const fetchProductsRequest = () => ({
  type: FETCH_PRODUCTS_REQUEST,
});

export const fetchProductsSuccess = (products) => ({
  type: FETCH_PRODUCTS_SUCCESS,
  payload: products,
});

export const fetchProductsFailure = (error) => ({
  type: FETCH_PRODUCTS_FAILURE,
  payload: error,
});

const product = [
  {
    id: "1",
    sku: "asdf123",
    name: "Shower Tap",
    price: 12.45,
    discount: 10,
    offerEnd: "October 5, 2020 12:11:00",
    new: false,
    rating: 4,
    saleCount: 54,
    category: ["fashion", "men"],
    tag: ["fashion", "men", "jacket", "full sleeve"],
    variation: [
      {
        color: "white",
        image: "/assets/img/product/fashion/8.jpg",
        size: [
          {
            name: "x",
            stock: 3,
          },
          {
            name: "m",
            stock: 2,
          },
          {
            name: "xl",
            stock: 5,
          },
        ],
      },
      {
        color: "black",
        image: "/assets/img/product/fashion/8.jpg",
        size: [
          {
            name: "x",
            stock: 4,
          },
          {
            name: "m",
            stock: 7,
          },
          {
            name: "xl",
            stock: 9,
          },
          {
            name: "xxl",
            stock: 1,
          },
        ],
      },
      {
        color: "brown",
        image: "/assets/img/product/fashion/8.jpg",
        size: [
          {
            name: "x",
            stock: 1,
          },
          {
            name: "m",
            stock: 2,
          },
          {
            name: "xl",
            stock: 4,
          },
          {
            name: "xxl",
            stock: 0,
          },
        ],
      },
    ],
    image: [
      "/assets/img/product/fashion/8.jpg",
      "/assets/img/product/fashion/8.jpg",
      "/assets/img/product/fashion/8.jpg",
      "/assets/img/product/fashion/8.jpg",
      "/assets/img/product/fashion/8.jpg",
    ],
    shortDescription:
      "Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur.",
    fullDescription:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur? Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure. To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure?",
  },
];

// {
//   "name": "Wireless Headphones",
//   "description": "High-quality wireless headphones with noise cancellation.",
//   "price": 199.99,
//   "currency": "USD",
//   "category": "Electronics",
//   "brand": "SoundWave",
//   "stock": 150,
//   "coverImage":"https://example.com/images/wireless-headphones-2.jpg",
//   "images": [
//       "https://example.com/images/wireless-headphones-1.jpg",
//       "https://example.com/images/wireless-headphones-2.jpg"
//   ],
//   "features": [
//       "Bluetooth 5.0",
//       "Active Noise Cancellation",
//       "20 hours battery life"
//   ]
// }

export const fetchProducts = () => {
  return async (dispatch) => {
    dispatch(fetchProductsRequest());
    try {
      const response = await axios.get(
        "https://us-central1-ecommerce-d8cf7.cloudfunctions.net/api/api/products"
      );
      console.log(response);

      dispatch(fetchProductsSuccess(response.data));
      // dispatch(fetchProductsSuccess(product));
    } catch (error) {
      dispatch(fetchProductsFailure(error.message));
    }
  };
};
