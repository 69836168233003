// import { FETCH_PRODUCTS_SUCCESS } from "../actions/productActions";

// const initState = {
//   products: []
// };

// const productReducer = (state = initState, action) => {
//   if (action.type === FETCH_PRODUCTS_SUCCESS) {
//     return {
//       ...state,
//       products: action.payload
//     };
//   }

//   return state;
// };

// export default productReducer;

import {
  FETCH_PRODUCTS_REQUEST,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_FAILURE,
} from "../actions/productActions";

const initState = {
  products: [],
  loading: false,
  error: null,
};

const productReducer = (state = initState, action) => {
  switch (action.type) {
    case FETCH_PRODUCTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        products: action.payload,
      };
    case FETCH_PRODUCTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

console.log(initState);

export default productReducer;
